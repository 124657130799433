<template>
  <div>
  <CCard>
   
  <CCardBody>
    <div class="clearfix">
      <button type="button" class="btn btn-primary float-xl-right" @click="navToNewModel">New Equipment</button>
    </div>

    <CDataTable :items="items"  
    :fields="fields"
      striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination
       :responsive="false">
       
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>

      <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
     
     
       <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                   <CDropdownItem :to="{name: 'Model', params: {modelId: item.modelId, mode: 'view'}}">View </CDropdownItem> 
              <CDropdownItem :to="{name: 'Checklists', params: {modelId: item.modelId,subGroupId: item.subGroupId}}">Checklists </CDropdownItem>
             <!-- <CDropdownItem :to="{name: 'Models', params: {modelId: item.modelId, mode: 'view'}}">Models </CDropdownItem> -->
                 <!-- <CDropdownItem :to="{name: 'Sub Groups', params: {groupId: item.groupId, mode: 'view'}}">Sub Groups</CDropdownItem>-->
                <ConfirmationModal
                      :parent="$refs.modalArea"
                      :description="'Are you sure to Delete'"
                      @on:ok="deleteModel(item.modelId)"
                    ></ConfirmationModal>

                </CDropdown> 

        
        </td>
      </template>
      </CDataTable>
  </CCardBody>
  <div ref="modalArea"></div>
<CCardFooter align="right">
         
            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
 </CCard>
 </div>
</template>

<script>
const fields = [
  'index',
  { key: "modelName",label: "Equipment", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  
  { key: "createdTime",label: "Created Date & Time", _classes: 'font-weight-bold', _style: "min-width:200px" },
   { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  
];
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "Models",
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getModels() {
      this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/models/"+this.$route.params.subGroupId)
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
     deleteModel(modelId) {
      console.log("delete called with", modelId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/delete/model/" +
         modelId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getModels();
             this.$nextTick(() => {
             this.$refs.vuetable.refresh();
           });
           window.location.reload();
        });
    },
    // deleteModel(modelId) {
    //   console.log("delete called with", modelId);
    //   this.apiGetSecure(
    //     process.env.VUE_APP_API_HOST +
    //       "/emapp/we/secure/delete/model/" +
    //      modelId
    //   )
    //     .then((response) => response.json())
    //     .then((data) => {
    //       this.getModels();
    //          this.$nextTick(() => {
    //          this.$refs.vuetable.refresh();
    //        });
    //        window.location.reload();
    //     });
    // },

    
    cancel() {
 
             this.$router.push({ name: "Sub Groups", params: {groupId: this.$route.params.groupId } });
    },
    navToNewModel(){
       this.$router.push({ name: "Model", params: { subGroupId:this.$route.params.subGroupId,mode: "new" } });
     }
  },
  mounted() {
    this.getModels();
  },
};
</script>